@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animate-fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s; /* Default duration */
    animation-fill-mode: forwards; /* Ensure the element stays visible after the animation */
  }
  